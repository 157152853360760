import React, { useState, useEffect } from "react";
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";

import { API_BASE_URL } from "config";
import { useHistory } from 'react-router-dom';

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

function AddsModule() {
  const history = useHistory();
  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingAd, setEditingAd] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });
  const [selectedFileType, setSelectedFileType] = useState('');
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleEdit = (ad) => {
    setEditingAd(ad);
    setFormData({ name: ad.name || '', description: ad.description || '' });
    setSelectedFileType('');
    setFile(null);
    onOpen();
  };

  const handleAddPostButton = () => {
    setEditingAd(null);
    setFormData({ name: '', description: '' });
    setSelectedFileType('');
    setFile(null);
    onOpen();
  };

  const handleDelete = async (ad) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/admin/in-game/ads-module/ads/${ad._id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        method: 'DELETE',
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push('/auth/signin');
          return;
        }
        throw new Error('Failed to delete ad');
      }
      fetchAds();
      alert('Deleted Successfully');
    } catch (error) {
      console.error('Error deleting ad:', error);
    }
  };

  const fetchAds = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/admin/in-game/ads-module/ads`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          history.push('/auth/signin');
          return;
        }
        throw new Error("Failed to fetch ads");
      }
      const data = await response.json();
      setAds(data.ads || []);
    } catch (error) {
      console.error("Error fetching ads:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAds();
  }, []);

  const handleFileTypeSelection = (type) => {
    setSelectedFileType(type);
    setFile(null); // Clear the file input when changing file type
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.description) newErrors.description = 'Description is required';
    if (!selectedFileType) newErrors.fileType = 'File type selection is required';
    if (!file) newErrors.file = `A ${selectedFileType} file is required`;
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = new FormData();
    data.append('name', formData.name);
    data.append('description', formData.description);

    if (file) data.append(selectedFileType, file);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/admin/in-game/ads-module/ads/${editingAd?._id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        method: 'PUT',
        body: data,
      });

      if (!response.ok) {
        if (response.status === 401) {
          history.push('/auth/signin');
          return;
        } else {
          const errorData = await response.json();
          setErrors(errorData.errors || {});
          return;
        }
      }

      onClose();
      fetchAds();
    } catch (error) {
      console.error('Submission error:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const data = new FormData();
    data.append('name', formData.name);
    data.append('description', formData.description);

    if (file) data.append(selectedFileType, file);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/admin/in-game/ads-module/ads`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        method: 'POST',
        body: data,
      });

      console.log(response);
      if (!response.ok) {
        if (response.status === 401) {
          history.push('/auth/signin');
          return;
        } else {
          const errorData = await response.json();
          setErrors(errorData.errors || {});
          return;
        }
      }

      onClose();
      fetchAds();
    } catch (error) {
      console.error('Submission error:', error);
    }
  };

  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>
      <Button onClick={handleAddPostButton} alignSelf='flex-end' mb='4' colorScheme='blue'>
        Add Post
      </Button>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='lg' fontWeight='bold'>
            Ads List
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Description</Th>
                <Th>Status</Th>
                <Th>File</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {ads.map((ad, key) => (
                <Tr key={key}>
                  <Td>{ad.name}</Td>
                  <Td>{ad.description}</Td>
                  <Td>{ad.status}</Td>
                  <Td>
                    {ad.file_type === 'image' && <Image src={`${API_BASE_URL}/uploads/images/${ad.file_name}`} width={100} height={100} alt="Image" />}
                    {ad.file_type === 'video' && <video width="100" height="100" controls>
                      <source src={`${API_BASE_URL}/uploads/videos/${ad.file_name}`} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>}
                    {ad.file_type === 'audio' && <audio controls>
                      <source src={`${API_BASE_URL}/uploads/audios/${ad.file_name}`} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>}
                  </Td>
                  <Td>
                    <Button colorScheme='blue' onClick={() => handleEdit(ad)}>Edit</Button>
                    <Button colorScheme='red' ml={2} onClick={() => handleDelete(ad)}>Delete</Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={editingAd ? handleUpdate : handleSubmit} encType="multipart/form-data">
            <ModalHeader>{editingAd ? 'Edit Ad' : 'Add a New Ad'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl isInvalid={!!errors.name}>
                <FormLabel>Name</FormLabel>
                <Input name="name" value={formData.name || ''} onChange={handleChange} placeholder="Name" />
                {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
              </FormControl>

              <FormControl mt={4} isInvalid={!!errors.description}>
                <FormLabel>Description</FormLabel>
                <Textarea name="description" value={formData.description || ''} onChange={handleChange} placeholder="Describe the ad..." />
                {errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
              </FormControl>

              <FormControl mt={4} isInvalid={!!errors.fileType}>
                <FormLabel>File Type</FormLabel>
                <Button onClick={() => handleFileTypeSelection('image')} mr={2}>Image</Button>
                <Button onClick={() => handleFileTypeSelection('video')} mr={2}>Video</Button>
                <Button onClick={() => handleFileTypeSelection('audio')}>Audio</Button>
                {errors.fileType && <FormErrorMessage>{errors.fileType}</FormErrorMessage>}
              </FormControl>

              {selectedFileType && (
                <FormControl mt={4} isInvalid={!!errors.file}>
                  <FormLabel>{selectedFileType.charAt(0).toUpperCase() + selectedFileType.slice(1)} File</FormLabel>
                  <Input type="file" name={selectedFileType} onChange={handleFileChange} />
                  {errors.file && <FormErrorMessage>{errors.file}</FormErrorMessage>}
                </FormControl>
              )}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                {editingAd ? 'Update' : 'Submit'}
              </Button>
              <Button colorScheme="blue" onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default AddsModule;
